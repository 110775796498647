import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import { 
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'
// import _ from 'lodash'  //lodash for cloneDeep, camelCase

import Layout from '../../components/layout'
// import FactionsMap from '../../components/factions/factions-map'

import '../../css/map.css'

class MapHome extends Component {
    constructor(props) {
        super(props)
        // this.polyGJRef = React.createRef();
        this.state = {
        }
    }
    componentDidMount(){
        let self = this
        setTimeout(()=>{
            window.location = `/${self.props.pageContext.lng}/map/europe`;
        },1000) // redirect after 1s
    }


    render() {

        return (
            <NamespacesConsumer>
            {(t, { i18n }) => {
                const lang = i18n.language.toLowerCase()
                // console.log(`lang = ${lang}`)

                return (
                    <Layout siteData={ { title:`${t("MU000")} | ${t("MU005")} | ${t("MU008")} | ${t("PT6000")}`, url:this.props.location.href } }>
                        <div  id="mapIndex">
                            <main role="main" className="main">
                                <Container>
                                    <Row>
                                        <Col className="text-center">  
                                            <Link id="mapList" to={`/${lang}/map/europe`}>
                                                <img src="/static-assets/worldmap.jpg" alt={`${t("PT6000")}`} className="img-fluid"/>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </main>
                        </div>
                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )

    }
}

export default withI18next()(MapHome)

export const query = graphql`
    query getMapHomeLang($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
